import React from "react";
import PropTypes from "prop-types";
import AOS from "aos";

export default function StepAnimator({ children }) {
  // re-initialize aos on re-renders and theme mode changes
  React.useEffect(() => {
    AOS.init({
      once: true,
      delay: 0,
      duration: 800,
      offset: 0,
      easing: "ease-in-out",
    });
  }, []);
  return <div>{children}</div>;
}

StepAnimator.propType = {
  children: PropTypes.node.isRequired,
};
