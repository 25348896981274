import React from "react";
import moment from "moment";
import axios from "axios";
import {
  STEP_ONE,
  STEP_THREE,
  STEP_TWO,
  STEP_FOUR,
} from "../contants/steps";
import Preamble from "./steps/stepone";
import QuotePrice from "./steps/steptwo";
import Personal from "./steps/stepthree";
import CoverageHistory from "./steps/stepfour";
import Container from "./container";
import Hero from "./hero";
import requestUrl from "../api/api";

function MyForm() {
  // country list
  const [countries, setCountries] = React.useState();

  // server state management
  const [state, setState] = React.useState({
    submitting: false,
    status: null,
    step: STEP_ONE,
  });

  // form state management
  const [form, setForm] = React.useState({
    name: "",
    message: "",
    dateOfBirth: "",
    gender: "",
    smoker: "",
    annualIncome: "",
    coverageAmount: 20000,
    policyLength: 10,
    monthlyPay: 0,
    height: "",
    weight: "",
    policies: {
      isExistingPolicies: "",
    },
    contactDetails: {
      firstName: "",
      lastName: "",
      email: "",
    },
    location: {
      address: "",
      street: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      birthCountry: "Select Country",
      birthCity: "",
    },
  });

  // form policies state management
  const updatePoliciesDetails = (key, value) => {
    setForm({
      ...form,
      policies: {
        ...form.policies,
        [key]: value,
      },
    });
    localStorage.setItem(
      "form",
      JSON.stringify({
        ...form,
        policies: {
          ...form.policies,
          [key]: value,
        },
      })
    );
  };

  // form contact state management
  const updateContactDetails = e => {
    setForm({
      ...form,
      contactDetails: {
        ...form.contactDetails,
        [e.target.name]: e.target.value,
      },
    });
    localStorage.setItem(
      "form",
      JSON.stringify({
        ...form,
        contactDetails: {
          ...form.contactDetails,
          [e.target.name]: e.target.value,
        },
      })
    );
  };

  // form location state management
  const updateLocationDetails = e => {
    let birthCity = form.location.birthCity;
    if (e.target.name === "birthCountry") {
      birthCity = "Select City";
    } else if (e.target.name === "birthCity") {
      birthCity = e.target.value;
    }
    setForm({
      ...form,
      location: {
        ...form.location,
        [e.target.name]: e.target.value,
        birthCity,
      },
    });
    localStorage.setItem(
      "form",
      JSON.stringify({
        ...form,
        location: {
          ...form.location,
          [e.target.name]: e.target.value,
        },
      })
    );
  };

  // handle main step navigation
  const handleMainStepNavigation = step => {
    setState(() => {
      return {
        ...state,
        step,
      };
    });
    localStorage.setItem(
      "state",
      JSON.stringify({
        ...state,
        step,
      })
    );
  };

  // handle form state change through event
  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    localStorage.setItem(
      "form",
      JSON.stringify({
        ...form,
        [e.target.name]: e.target.value,
      })
    );
  };

  // handle form state change manually
  const handleFormManualChange = (key, value) => {
    let monthlyPay = form.monthlyPay;
    if (key === "coverageAmount") {
      monthlyPay = parseFloat(
        value / (form.policyLength * 12)
      ).toFixed(2);
    }
    if (key === "policyLength") {
      monthlyPay = parseFloat(
        form.coverageAmount / (value * 12)
      ).toFixed(2);
    }
    setForm({
      ...form,
      [key]: value,
      monthlyPay,
    });
    localStorage.setItem(
      "form",
      JSON.stringify({
        ...form,
        [key]: value,
      })
    );
  };

  // handle form submisssin feedback
  const handleServerResponse = (ok, msg) => {
    setState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setForm({
        email: "",
        message: "",
        dateOfBirth: "",
      });
    }
  };

  // handle form submission
  const handleOnSubmit = event => {
    event.preventDefault();
    setState({ ...state, submitting: true });
    axios({
      method: "POST",
      url: `https://formspree.io/f/${process.env.REACT_APP_API_KEY} || xvolbkpe`,
      data: form,
    })
      .then(r => {
        console.log(r);
        console.log("went!");
        handleServerResponse(true, "Thanks!");
      })
      .catch(e => {
        console.log(e);
        handleServerResponse(false, e.response.data.error);
      });
  };

  // all form logic
  const formLogic = {
    form,
    countries,
    handleChange,
    handleFormManualChange,
    handleOnSubmit,
    handleMainStepNavigation,
    updateContactDetails,
    updateLocationDetails,
    updatePoliciesDetails,
  };

  // date coverter
  const getDateFormat = date => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };

  // get progress in localStorage when page reloads
  React.useEffect(() => {
    const fetchCountriesCities = async () => {
      const response = await fetch(requestUrl);
      let { data } = await response.json();
      data = [...data].map(country => ({
        country: {
          label: country.country,
          value: country.country,
        },
        cities: country.cities.map(city => ({
          label: city,
          value: city,
        })),
      }));
      setCountries(data);
    };
    fetchCountriesCities();
    const savedForm = localStorage.getItem("form");
    const savedState = localStorage.getItem("state");
    if (savedForm) {
      const initialFormValues = JSON.parse(savedForm);
      let { dateOfBirth } = initialFormValues;
      dateOfBirth = getDateFormat(dateOfBirth);
      setForm({
        ...initialFormValues,
        dateOfBirth,
      });
    }
    if (savedState) {
      setState(JSON.parse(savedState));
    }
  }, []);

  return (
    <>
      <Hero />
      <form onSubmit={handleOnSubmit}>
        <Container>
          {state.step === STEP_ONE && (
            <Preamble {...formLogic} />
          )}
          {state.step === STEP_TWO && (
            <QuotePrice {...formLogic} />
          )}
          {state.step === STEP_THREE && (
            <Personal {...formLogic} />
          )}
          {state.step === STEP_FOUR && (
            <CoverageHistory {...formLogic} />
          )}
          {/* <button onClick={handleOnSubmit} type="submit">
            {state.submitting ? "Submitting..." : "Submit"}
          </button> */}
        </Container>
      </form>
    </>
  );
}

export default MyForm;
