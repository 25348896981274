import React from "react";
import StepAnimator from "./components/stepAnimator";
import MyForm from "./components/formContainmer";
import ButtonProvider from "./context/buttonContext";

function App() {
  return (
    <ButtonProvider>
      <StepAnimator>
        <MyForm />
      </StepAnimator>
    </ButtonProvider>
  );
}

export default App;
