import React, { useState } from "react";
import {
  MainBody,
  HeadingTwo,
  SubBody,
} from "../../styled/headings";
import { Space, Input } from "antd";
import { ButtonLarge } from "../../styled/buttons";
import { STEP_FOUR, STEP_TWO } from "../../contants/steps";
import QuestionHeader from "../questionheader";
import Shadowcontainer from "../shadowcontainer";
import StepWrapper from "../stepWrapper";
import Iconify from "../iconify";
import SearchableSelect from "../serachableSelect";
/** @jsxImportSource @emotion/react */
import "twin.macro";

// import global button context
import { useButtonContext } from "../../context/buttonContext";

const SubStepItem = ({
  header,
  buttonText,
  onClick,
  children,
  disabled,
}) => {
  return (
    <StepWrapper isLarge>
      <Shadowcontainer>
        <QuestionHeader>
          <MainBody>{header}</MainBody>
        </QuestionHeader>
        <QuestionHeader.Body>
          {children}
        </QuestionHeader.Body>
      </Shadowcontainer>
      <div tw="w-7/12 lg:w-5/12 text-center mx-auto">
        <ButtonLarge
          block
          disabled={disabled}
          onClick={onClick}>
          {buttonText || "Next"}
        </ButtonLarge>
      </div>
    </StepWrapper>
  );
};

function Personal({
  form,
  countries,
  handleChange,
  handleMainStepNavigation,
  updateContactDetails,
  updateLocationDetails,
}) {
  const { updateNavigationFunc, lastStep, fixSubItem } =
    useButtonContext();
  console.log(lastStep);
  const { contactDetails, location } = form;
  const [cities, setCities] = useState([]);
  const [subStep, setSubStep] = useState(lastStep || 1);

  const handleStepComplete = () => {
    setSubStep(() => {
      handleMainStepNavigation(STEP_FOUR);
      return 1;
    });
    fixSubItem(1);
  };

  // select country callback
  const onSelectCountry = value => {
    setCities([]);
    const matchingCities = countries.find(
      country => country.country.value === value
    );
    setCities(matchingCities.cities);
    updateLocationDetails({
      target: { name: "birthCountry", value },
    });
  };

  // select city callback
  const onSelectCity = value => {
    updateLocationDetails({
      target: { name: "birthCity", value },
    });
  };
  React.useEffect(() => {
    const handleNavigation = () => {
      if (subStep === 1) {
        handleMainStepNavigation(STEP_TWO);
      }
      setSubStep(subStep - 1);
    };

    if (updateNavigationFunc) {
      updateNavigationFunc(handleNavigation, subStep);
    }
  }, [
    handleMainStepNavigation,
    subStep,
    updateNavigationFunc,
  ]);
  return (
    <>
      {subStep === 1 && (
        <>
          <HeadingTwo tw="text-center max-w-2xl mb-4">
            {
              "Answer a few questions and submit your application in few seconds!"
            }
          </HeadingTwo>
          <Space>
            <Iconify
              icon="fa6-solid:sack-dollar"
              width={40}
              height={40}
            />
            <SubBody tw="ml-2">
              There are no fees or obligations to submit.
              <br />
              You can change your mind anytime
            </SubBody>
          </Space>
          <div tw="mt-6" />
          <SubStepItem
            header={
              <div tw="px-6">
                <MainBody tw="font-medium">
                  Your contact details
                </MainBody>
                <SubBody tw="font-bold">
                  We will use your email as our primary
                  means of contacting you about our coverage
                </SubBody>
              </div>
            }
            disabled={
              !form.contactDetails.firstName ||
              !form.contactDetails.lastName ||
              !form.contactDetails.email
            }
            onClick={() => setSubStep(2)}>
            <div tw="md:w-4/5 lg:w-1/2 mx-auto">
              <div tw="mb-3">
                <Input
                  placeholder={"First Name"}
                  size="large"
                  name={"firstName"}
                  value={contactDetails.firstName}
                  onChange={updateContactDetails}
                />
              </div>
              <div tw="mb-3">
                <Input
                  placeholder={"Last Name"}
                  size="large"
                  name={"lastName"}
                  value={contactDetails.lastName}
                  onChange={updateContactDetails}
                />
              </div>
              <div tw="mb-3">
                <Input
                  placeholder={"Email"}
                  size="large"
                  name={"email"}
                  value={contactDetails.email}
                  onChange={updateContactDetails}
                />
              </div>
            </div>
          </SubStepItem>
        </>
      )}
      {subStep === 2 && (
        <>
          <HeadingTwo tw="text-center max-w-2xl mb-4">
            {
              "Answer a few questions and submit your application in few seconds!"
            }
          </HeadingTwo>
          <Space>
            <Iconify
              icon="fa6-solid:sack-dollar"
              width={40}
              height={40}
            />
            <SubBody tw="ml-2">
              There are no fees or obligations to submit.
              <br />
              You can change your mind anytime
            </SubBody>
          </Space>
          <div tw="mt-6" />
          <SubStepItem
            header={
              <div tw="px-6">
                <MainBody tw="font-medium">
                  {`Where do you live ${contactDetails.firstName}?`}
                </MainBody>
              </div>
            }
            disabled={
              !location.address ||
              !location.city ||
              !location.street ||
              !location.province ||
              !location.postalCode ||
              !location.country
            }
            onClick={() => setSubStep(3)}>
            <div tw="md:w-4/5 lg:w-1/2 mx-auto">
              <div tw="mb-3">
                <Input
                  placeholder={"Your Address"}
                  size="large"
                  name={"address"}
                  value={location.address}
                  onChange={updateLocationDetails}
                />
              </div>
              {location.address && (
                <>
                  <div tw="mb-3">
                    <Input
                      placeholder={"Your Street"}
                      size="large"
                      name={"street"}
                      value={location.street}
                      onChange={updateLocationDetails}
                    />
                  </div>
                  <div tw="mb-3">
                    <Input
                      placeholder={"Your city"}
                      size="large"
                      name={"city"}
                      value={location.city}
                      onChange={updateLocationDetails}
                    />
                  </div>
                  <div tw="mb-3">
                    <Input
                      placeholder={"Your Province"}
                      size="large"
                      name={"province"}
                      value={location.province}
                      onChange={updateLocationDetails}
                    />
                  </div>
                  <div tw="mb-3">
                    <Input
                      placeholder={"Your Country"}
                      size="large"
                      name={"country"}
                      value={location.country}
                      onChange={updateLocationDetails}
                    />
                  </div>
                  <div tw="mb-3">
                    <Input
                      placeholder={"Your Postal Code"}
                      size="large"
                      name={"postalCode"}
                      value={location.postalCode}
                      onChange={updateLocationDetails}
                    />
                  </div>
                </>
              )}
            </div>
          </SubStepItem>
        </>
      )}
      {subStep === 3 && (
        <>
          <HeadingTwo tw="text-center max-w-2xl mb-4">
            {
              "Answer a few questions and submit your application in few seconds!"
            }
          </HeadingTwo>
          <Space>
            <Iconify
              icon="fa6-solid:sack-dollar"
              width={40}
              height={40}
            />
            <SubBody tw="ml-2">
              There are no fees or obligations to submit.
              <br />
              You can change your mind anytime
            </SubBody>
          </Space>
          <div tw="mt-6" />
          <SubStepItem
            header={
              <div tw="px-6">
                <MainBody tw="font-medium">
                  {`Where were you born ${contactDetails.firstName}?`}
                </MainBody>
              </div>
            }
            disabled={
              location.birthCountry === "Select Country" ||
              location.birthCity === "Select City"
            }
            onClick={() => setSubStep(4)}>
            <div tw="md:w-4/5 lg:w-1/2 mx-auto">
              <div tw="mb-3">
                <SearchableSelect
                  value={location.birthCountry}
                  tw="w-full! py-4!"
                  placeholder={"Search your Country"}
                  options={countries.map(item => ({
                    ...item.country,
                  }))}
                  onSelect={value => onSelectCountry(value)}
                />
              </div>
              {location.birthCountry !==
                "Select Country" && (
                <div tw="mb-3">
                  <SearchableSelect
                    value={location.birthCity}
                    tw="w-full! py-4!"
                    placeholder={"Search your City"}
                    options={cities}
                    onSelect={value => onSelectCity(value)}
                  />
                </div>
              )}
            </div>
          </SubStepItem>
        </>
      )}
      {subStep === 4 && (
        <>
          <HeadingTwo tw="text-center max-w-2xl mb-4">
            {
              "Answer a few questions and submit your application in few seconds!"
            }
          </HeadingTwo>
          <Space>
            <Iconify
              icon="fa6-solid:sack-dollar"
              width={40}
              height={40}
            />
            <SubBody tw="ml-2">
              {
                "Don't worry about exact figures, an estimate is okay."
              }
            </SubBody>
          </Space>
          <div tw="mt-6" />
          <SubStepItem
            header={
              <div tw="px-6">
                <MainBody tw="font-medium">
                  {
                    "Please estimate your annual income (before taxes)"
                  }
                </MainBody>
              </div>
            }
            disabled={!form.annualIncome}
            onClick={() => setSubStep(5)}>
            <div tw="md:w-4/5 lg:w-1/2 mx-auto">
              <div tw="mb-3">
                <Input
                  placeholder={"Your Annual Income in $"}
                  type="number"
                  size="large"
                  name={"annualIncome"}
                  value={form.annualIncome}
                  onChange={handleChange}
                />
              </div>
            </div>
          </SubStepItem>
        </>
      )}
      {subStep === 5 && (
        <>
          <div tw="mt-6" />
          <SubStepItem
            header={
              <div tw="px-6">
                <MainBody tw="font-medium">
                  {"What is your weight?"}
                </MainBody>
              </div>
            }
            disabled={!form.weight}
            onClick={() => setSubStep(6)}>
            <div tw="md:w-4/5 lg:w-1/2 mx-auto">
              <div tw="mb-3">
                <Input
                  placeholder={"Your weight in kg"}
                  type="number"
                  size="large"
                  name={"weight"}
                  value={form.weight}
                  onChange={handleChange}
                />
              </div>
            </div>
          </SubStepItem>
        </>
      )}
      {subStep === 6 && (
        <>
          <div tw="mt-6" />
          <SubStepItem
            header={
              <div tw="px-6">
                <MainBody tw="font-medium">
                  {"What is your height?"}
                </MainBody>
              </div>
            }
            disabled={!form.height}
            onClick={() => handleStepComplete()}>
            <div tw="md:w-4/5 lg:w-1/2 mx-auto">
              <div tw="mb-3">
                <Input
                  placeholder={"Your height in meters"}
                  type="number"
                  size="large"
                  name={"height"}
                  value={form.height}
                  onChange={handleChange}
                />
              </div>
            </div>
          </SubStepItem>
        </>
      )}
    </>
  );
}

export default Personal;
