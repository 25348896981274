import React from "react";
import PropTypes from "prop-types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

function StepWrapper({ children, isLarge }) {
  if (isLarge) {
    return (
      <div
        tw="w-10/12 md:w-3/4 lg:w-1/2"
        data-aos="fade-left"
        className="stepper-content">
        {children}
      </div>
    );
  }
  return (
    <div
      tw="w-10/12 md:w-1/2 lg:w-1/3"
      data-aos="fade-left"
      className="stepper-content">
      {children}
    </div>
  );
}

StepWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepWrapper;
