import React from "react";
import { Image, Space } from "antd";
import { MainBody } from "../styled/headings";
import Iconify from "./iconify";
/** @jsxImportSource @emotion/react */
import "twin.macro";

// import global button context
import { useButtonContext } from "../context/buttonContext";

function Hero() {
  const { handleHeroButtonClick } = useButtonContext();
  return (
    <div tw="bg-white text-black!  border-b border-b-[#f1f1f1] relative flex items-center justify-center h-16 py-8 lg:(py-[46px] w-full)">
      <button
        tw="absolute left-0 lg:left-5"
        onClick={handleHeroButtonClick}>
        <Space align="center" size={"small"}>
          <Iconify
            tw="mt-1"
            icon="eva:arrow-ios-back-outline"
            height={25}
            width={25}
          />
          <MainBody tw="text-black font-semibold">
            Back
          </MainBody>
        </Space>
      </button>
      <div tw="ml-auto mr-6 md:mx-auto ">
        <Image
          src="/images/hero.jpg"
          height={50}
          preview={false}
        />
      </div>
    </div>
  );
}

export default Hero;
