import React from "react";
/** @jsxImportSource @emotion/react */
import "twin.macro";

function QuestionBody({ children }) {
  return (
    <div tw="bg-[#Fff] py-6 text-center px-2 w-full">
      {children}
    </div>
  );
}

function QuestionHeader({ children }) {
  return (
    <div tw="bg-[#F4F8FE] py-6 text-center px-2 w-full">
      {children}
    </div>
  );
}

QuestionHeader.Body = QuestionBody;

export default QuestionHeader;
