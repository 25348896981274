import React from "react";
import { Select } from "antd";

export default function SearchableSelect({
  onSelect,
  options,
  placeholder,
  ...rest
}) {
  return (
    <Select
      placeholder={placeholder}
      onSelect={onSelect}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      {...rest}>
      {options.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}
