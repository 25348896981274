import React, { useState } from "react";
import { MainBody } from "../../styled/headings";
import { Input, DatePicker } from "antd";
import {
  ButtonLarge,
  ButtonMedium,
} from "../../styled/buttons";
import {
  STEP_FIVE,
  STEP_THREE,
} from "../../contants/steps";
import QuestionHeader from "../questionheader";
import Shadowcontainer from "../shadowcontainer";
import StepWrapper from "../stepWrapper";
import SearchableSelect from "../serachableSelect";
/** @jsxImportSource @emotion/react */
import "twin.macro";

// import global button context
import { useButtonContext } from "../../context/buttonContext";

// insurers
const insurers = [
  {
    label: "Assumption life",
    value: "Assumption life",
  },
  {
    label: "BMO",
    value: "BMO",
  },
  {
    label: "Canada life",
    value: "Canada Life",
  },
  {
    label: "Canada Protection Plan (CPP)",
    value: "Canada Protection Plan (CPP)",
  },
  {
    label: "Empire life",
    value: "Empire life",
  },
  {
    label: "Co-operators",
    value: "Co-operators",
  },
  {
    label: "Desjardins",
    value: "Desjardins",
  },
];

//policy types
const policy_types = [
  {
    label: "Term life Insurance",
    value: "Term life Insurance",
  },
  {
    label: "Universal life Insurance",
    value: "Universal life Insurance",
  },
  {
    label: "Whole life Insurance",
    value: "Whole life Insurance",
  },
  {
    label: "Canada Protection Plan (CPP)",
    value: "Canada Protection Plan (CPP)",
  },
  {
    label: "Mortgage life Insurance",
    value: "Mortgage life Insurance",
  },
  {
    label: "Group life Insurance (Through work)",
    value: "Group life Insurance (Through work)",
  },
  {
    label: "Others",
    value: "Others",
  },
];

const SubStepItem = ({
  header,
  buttonText,
  onClick,
  children,
  disabled,
}) => {
  return (
    <StepWrapper>
      <Shadowcontainer>
        <QuestionHeader>
          <MainBody>{header}</MainBody>
        </QuestionHeader>
        <QuestionHeader.Body>
          {children}
        </QuestionHeader.Body>
      </Shadowcontainer>
      <div tw="w-10/12 lg:w-8/12 text-center mx-auto">
        <ButtonLarge
          block
          disabled={disabled}
          onClick={onClick}>
          {buttonText || "Next"}
        </ButtonLarge>
      </div>
    </StepWrapper>
  );
};

function CoverageHistory({
  form,
  updatePoliciesDetails,
  handleMainStepNavigation,
}) {
  const { updateNavigationFunc, lastStep } =
    useButtonContext();
  const [subStep, setSubStep] = useState(lastStep || 1);
  console.log(lastStep);
  const handleStepComplete = () => {
    setSubStep(() => {
      handleMainStepNavigation(STEP_FIVE);
      return 1;
    });
  };
  React.useEffect(() => {
    const handleNavigation = () => {
      if (subStep === 1) {
        handleMainStepNavigation(STEP_THREE);
      }
      setSubStep(subStep - 1);
    };
    console.log(SubStepItem === 6);
    if (updateNavigationFunc) {
      updateNavigationFunc(handleNavigation, subStep);
    }
  }, [
    handleMainStepNavigation,
    subStep,
    updateNavigationFunc,
  ]);
  return (
    <>
      {subStep === 1 && (
        <SubStepItem
          header={
            "Excluding Policies through work, do yo have any exiting life insurance policies?"
          }
          disabled={form.policies.isExistingPolicies === ""}
          onClick={() =>
            form.policies.isExistingPolicies
              ? setSubStep(2)
              : handleStepComplete()
          }>
          <div tw="flex justify-between gap-4 w-11/12 mx-auto">
            <div
              className={
                form.policies.isExistingPolicies === true
                  ? "active"
                  : ""
              }
              tw="w-full">
              <ButtonMedium
                type={"outlined"}
                block
                onClick={() =>
                  updatePoliciesDetails(
                    "isExistingPolicies",
                    true
                  )
                }>
                Yes
              </ButtonMedium>
            </div>
            <div
              className={
                form.policies.isExistingPolicies === false
                  ? "active"
                  : ""
              }
              tw="w-full">
              <ButtonMedium
                type={"outlined"}
                block
                onClick={() =>
                  updatePoliciesDetails(
                    "isExistingPolicies",
                    false
                  )
                }>
                No
              </ButtonMedium>
            </div>
          </div>
        </SubStepItem>
      )}
      {subStep === 2 && (
        <SubStepItem
          header={
            "Please Provide more details about your current insurance policy"
          }
          disabled={!form.dateOfBirth}
          onClick={() => handleStepComplete()}>
          <div tw="md:w-4/5 lg:w-1/2 mx-auto">
            <div tw="mb-2">
              <SearchableSelect
                // value={location.birthCountry}
                tw="w-full! py-4!"
                placeholder={"Insurer"}
                options={insurers}
                // onSelect={value => onSelectCountry(value)}
              />
            </div>
            <div tw="mb-5">
              <Input
                placeholder={"Coverage Amount in $"}
                name={"annualIncome"}
                type="number"
                // value={form.annualIncome}
                // onChange={handleChange}
              />
            </div>
            <div tw="mb-1">
              <DatePicker
                tw="w-full!"
                // onChange={onChange}
                picker="year"
              />
            </div>
            <div tw="mb-3">
              <SearchableSelect
                // value={location.birthCountry}
                tw="w-full! py-4!"
                placeholder={"Policy Type"}
                options={policy_types}
                // onSelect={value => onSelectCountry(value)}
              />
            </div>
          </div>
        </SubStepItem>
      )}
    </>
  );
}

export default CoverageHistory;
