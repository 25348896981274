import React from "react";
import { Button } from "antd";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const ButtonLarge = ({
  children,
  type,
  style,
  ...rest
}) => {
  return (
    <Button
      style={{
        ...style,
      }}
      type={type || "primary"}
      size="large"
      {...rest}
      tw="font-medium text-lg">
      {children}
    </Button>
  );
};

export const ButtonMedium = ({
  children,
  type,
  style,
  ...rest
}) => {
  return (
    <Button
      style={{
        ...style,
      }}
      type={type || "primary"}
      size="middle"
      {...rest}
      tw="font-medium text-lg">
      {children}
    </Button>
  );
};

export const TextButtonMedium = ({
  children,
  type,
  style,
  ...rest
}) => {
  return (
    <Button
      type="text"
      size="middle"
      style={{
        ...style,
      }}
      {...rest}
      tw="font-medium text-lg text-primary_50!">
      {children}
    </Button>
  );
};
