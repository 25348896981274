import React from "react";
/** @jsxImportSource @emotion/react */
import "twin.macro";

function Container({ children }) {
  return (
    <div tw="flex flex-col items-center  bg-[#F8FAFB] py-10 lg:py-20  min-h-[calc(100vh - 80px)] lg:min-h-[calc(100vh - 80px)] w-screen">
      {children}
    </div>
  );
}

export default Container;
