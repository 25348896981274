import React from "react";
import PropTypes from "prop-types";
// icons
import { Icon } from "@iconify/react";
/** @jsxImportSource @emotion/react */
import "twin.macro";
export default function Iconify({
  icon,
  width = "16px",
  height = "16px",
  ...rest
}) {
  return (
    <div
      tw="inline-block"
      style={{
        marginLeft: "15px",
        marginBottom: "-2px",
        cursor: "pointer",
      }}>
      <Icon
        {...rest}
        icon={icon}
        width={width}
        height={height}
      />
    </div>
  );
}

Iconify.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  sx: PropTypes.object,
};
