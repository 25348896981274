import React, { useState } from "react";
import {
  MainBody,
  HeadingTwo,
  SubBody,
} from "../../styled/headings";
import { DatePicker, Modal } from "antd";
import {
  ButtonLarge,
  ButtonMedium,
} from "../../styled/buttons";
import { STEP_TWO } from "../../contants/steps";
import QuestionHeader from "../questionheader";
import Shadowcontainer from "../shadowcontainer";
import StepWrapper from "../stepWrapper";
import Iconify from "../iconify";
/** @jsxImportSource @emotion/react */
import "twin.macro";

// import global button context
import { useButtonContext } from "../../context/buttonContext";

const hardProducts = [
  "Cigarettes",
  "E-Cigarettes",
  "Pipes",
  "Chewing Tobacco",
  "Vaporizers",
  "Nicotine Gum",
  "Nicotine patches or",
  "13 or more Cigars",
];

const SubStepItem = ({
  header,
  buttonText,
  onClick,
  children,
  disabled,
}) => {
  return (
    <StepWrapper>
      <Shadowcontainer>
        <QuestionHeader>
          <MainBody>{header}</MainBody>
        </QuestionHeader>
        <QuestionHeader.Body>
          {children}
        </QuestionHeader.Body>
      </Shadowcontainer>
      <div tw="w-10/12 lg:w-8/12 text-center mx-auto">
        <ButtonLarge
          block
          disabled={disabled}
          onClick={onClick}>
          {buttonText || "Next"}
        </ButtonLarge>
      </div>
    </StepWrapper>
  );
};

function Preamble({
  form,
  handleFormManualChange,
  handleMainStepNavigation,
}) {
  const { updateNavigationFunc, lastStep } =
    useButtonContext();
  const [subStep, setSubStep] = useState(lastStep || 1);
  const [isModalOpen, setIsModalOpen] =
    React.useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleStepComplete = () => {
    setSubStep(() => {
      handleMainStepNavigation(STEP_TWO);
      return 1;
    });
  };

  React.useEffect(() => {
    const handleNavigation = () => {
      if (subStep === 1) return;
      setSubStep(subStep - 1);
    };
    if (updateNavigationFunc) {
      updateNavigationFunc(handleNavigation, subStep);
    }
  }, [subStep, updateNavigationFunc]);
  return (
    <>
      <Modal
        title="What is considered nicotine or tobacco products?"
        footer={false}
        visible={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}>
        <div tw="w-full h-full ">
          <SubBody tw="font-medium">
            You should select <strong>Yes</strong> if you
            have used Tobacco and Nicotine related products
            in the last <strong>12 months.</strong>
            <br />
            Tobacco and Nicotine products are products
            include:
            <br />
            <br />
            <ul tw="list-disc pl-6">
              {hardProducts.map(product => (
                <li key={product}>
                  <SubBody>{product}</SubBody>
                </li>
              ))}
            </ul>
            <br />
            <SubBody>
              Otherwise, select <strong>No.</strong> Note
              that cannabis and its related products, are
              not considered as Nicotine or Tobacco
              products.
            </SubBody>
          </SubBody>
        </div>
      </Modal>
      <HeadingTwo tw="text-center">
        {"Lets get you an instant quote!"}
      </HeadingTwo>
      {subStep === 1 && (
        <SubStepItem
          header={"Your date of birth"}
          disabled={!form.dateOfBirth}
          onClick={() => setSubStep(2)}>
          <DatePicker
            tw="w-4/5!"
            value={form.dateOfBirth}
            onChange={date => {
              handleFormManualChange(
                "dateOfBirth",
                date ? date : ""
              );
            }}
          />
        </SubStepItem>
      )}
      {subStep === 2 && (
        <SubStepItem
          header={"What is your gender?"}
          disabled={!form.gender}
          onClick={() => setSubStep(3)}>
          <div tw="flex justify-between gap-4 w-11/12 mx-auto">
            <div
              className={
                form.gender === "Male" ? "active" : ""
              }
              tw="w-full">
              <ButtonMedium
                type={"outlined"}
                block
                onClick={() =>
                  handleFormManualChange("gender", "Male")
                }>
                Male
              </ButtonMedium>
            </div>
            <div
              className={
                form.gender === "Female" ? "active" : ""
              }
              tw="w-full">
              <ButtonMedium
                type={"outlined"}
                block
                onClick={() =>
                  handleFormManualChange("gender", "Female")
                }>
                Female
              </ButtonMedium>
            </div>
          </div>
        </SubStepItem>
      )}
      {subStep === 3 && (
        <SubStepItem
          header={
            <span>
              Have you used nicotine or tobacco related
              product in the last 12 months?
              <Iconify
                icon="ic:outline-help"
                height={25}
                width={25}
                onClick={() => setIsModalOpen(true)}
              />
            </span>
          }
          disabled={form.smoker === ""}
          onClick={handleStepComplete}>
          <div tw="flex justify-between gap-4 w-11/12 mx-auto">
            <div
              className={
                form.smoker === false ? "active" : ""
              }
              tw="w-full">
              <ButtonMedium
                type={"outlined"}
                block
                onClick={() =>
                  handleFormManualChange("smoker", false)
                }>
                No
              </ButtonMedium>
            </div>
            <div
              className={
                form.smoker === true ? "active" : ""
              }
              tw="w-full">
              <ButtonMedium
                type={"outlined"}
                block
                onClick={() =>
                  handleFormManualChange("smoker", true)
                }>
                Yes
              </ButtonMedium>
            </div>
          </div>
        </SubStepItem>
      )}
    </>
  );
}

export default Preamble;
