import React from "react";
/** @jsxImportSource @emotion/react */
import "twin.macro";

function Shadowcontainer({ children, ...rest }) {
  return (
    <div tw="shadow-md bg-white mt-4 mb-10" {...rest}>
      {children}
    </div>
  );
}

export default Shadowcontainer;
