import React, { useCallback } from "react";

const ButtonContext = React.createContext();

const ButtonProvider = ({ children }) => {
  const [lastStep, setLastStep] = React.useState(0);
  const [navigationFunc, setNavigationFunc] =
    React.useState(() => () => {});
  const handleHeroButtonClick = () => {
    navigationFunc();
  };

  const updateNavigationFunc = useCallback(
    (func = () => {}, step = 1) => {
      setLastStep(step);
      setNavigationFunc(() => func);
    },
    [setLastStep]
  );

  const fixSubItem = step => {
    setLastStep(step);
  };
  return (
    <ButtonContext.Provider
      value={{
        handleHeroButtonClick,
        updateNavigationFunc,
        lastStep,
        fixSubItem,
      }}>
      {children}
    </ButtonContext.Provider>
  );
};

export const useButtonContext = () => {
  return React.useContext(ButtonContext);
};

export default ButtonProvider;
