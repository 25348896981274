import React from "react";
import { Typography } from "antd";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const HeadingOne = ({ children, ...rest }) => {
  return (
    <Typography.Title
      tw="text-[32px]! leading-[42px]! lg:(text-[56px]! leading-[74px]! font-bold!)"
      {...rest}>
      {children}
    </Typography.Title>
  );
};

export const HeadingTwo = ({ children, ...rest }) => {
  return (
    <Typography.Title
      {...rest}
      tw="text-[24px]! leading-[32px]! font-semibold! lg:(text-[32px]! leading-[44px]! font-bold!)">
      {children}
    </Typography.Title>
  );
};

export const HeadingThree = ({ children, ...rest }) => {
  return (
    <Typography.Title
      {...rest}
      tw="text-[18px]! leading-[24px]! font-bold! lg:(text-[32px]! leading-[42px]! font-semibold!)">
      {children}
    </Typography.Title>
  );
};

export const HeadingFour = ({ children, ...rest }) => {
  return (
    <Typography.Title
      tw="text-[16px]! leading-[23px]! font-bold! lg:(text-[24px]! leading-[32px]! font-bold!)"
      {...rest}>
      {children}
    </Typography.Title>
  );
};

export const HeadingFive = ({ children, ...rest }) => {
  return (
    <Typography.Title
      tw="text-[14px]! leading-[23px]! font-bold! lg:(text-[18px]! leading-[24px]! font-bold!)"
      {...rest}>
      {children}
    </Typography.Title>
  );
};

export const HeadingSix = ({ children, ...rest }) => {
  return (
    <Typography.Title
      tw="text-[14px]! leading-[23px]! font-bold! lg:(text-[16px]! leading-[22px]! font-bold!)"
      {...rest}>
      {children}
    </Typography.Title>
  );
};

export const MainBody = ({ children, ...rest }) => {
  return (
    <Typography.Text
      tw="text-[22px]! leading-[35px]! font-normal"
      {...rest}>
      {children}
    </Typography.Text>
  );
};

export const SubBody = ({ children, ...rest }) => {
  return (
    <p
      tw="text-[18px]! leading-[26px]! font-normal"
      {...rest}>
      {children}
    </p>
  );
};

export const MiniBody = ({ children, ...rest }) => {
  return (
    <p
      tw="text-[14px]! leading-[23px]! font-light"
      {...rest}>
      {children}
    </p>
  );
};
