import React, { useState } from "react";
import { Space, Slider } from "antd";
import {
  MainBody,
  HeadingTwo,
  SubBody,
} from "../../styled/headings";
import { ButtonLarge } from "../../styled/buttons";
import { STEP_THREE, STEP_ONE } from "../../contants/steps";
import QuestionHeader from "../questionheader";
import Shadowcontainer from "../shadowcontainer";
import StepWrapper from "../stepWrapper";
import Iconify from "../iconify";
/** @jsxImportSource @emotion/react */
import "twin.macro";

// import global button context
import { useButtonContext } from "../../context/buttonContext";

const SubStepItem = ({
  form,
  buttonText,
  onClick,
  disabled,
  reset,
  handleFormManualChange,
}) => {
  const [money, setMoney] = useState(form.coverageAmount);
  const [year, setYear] = useState(form.policyLength);

  // money slider markers
  const moneyMarks = {
    0: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"$0"}</strong>,
    },
    3000000: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"$3M"}</strong>,
    },
  };
  // money slider markers
  const yearMarks = {
    10: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"10"}</strong>,
    },
    15: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"15"}</strong>,
    },
    20: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"20"}</strong>,
    },
    25: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"25"}</strong>,
    },
    30: {
      style: {
        color: "#1e86ff",
      },
      label: <strong>{"30"}</strong>,
    },
  };

  // money slider tooltip
  function moneyFormatter(value) {
    return (
      <div tw="px-6 flex flex-col py-4 bg-white">
        <MainBody tw="text-black! text-center">
          {`$${value}`}
        </MainBody>
      </div>
    );
  }

  // money slider tooltip
  function yearFormatter(value) {
    return (
      <div tw="px-6 flex flex-col py-4 bg-white">
        <MainBody tw="text-black! text-center">
          {`${value}years`}
        </MainBody>
      </div>
    );
  }

  return (
    <StepWrapper isLarge>
      <div tw="grid grid-cols-1 lg:(grid-cols-2 gap-x-8)">
        <Shadowcontainer>
          <QuestionHeader.Body>
            <div tw="w-full px-4 font-medium">
              <MainBody>{"Coverage Amount"}</MainBody>
              <Slider
                tipFormatter={moneyFormatter}
                marks={moneyMarks}
                defaultValue={money}
                max={3000000}
                onChange={value => {
                  setMoney(value);
                  handleFormManualChange(
                    "coverageAmount",
                    value
                  );
                }}
              />
            </div>
          </QuestionHeader.Body>
        </Shadowcontainer>
        <Shadowcontainer>
          <QuestionHeader.Body>
            <div tw="w-full px-4 font-medium">
              <MainBody>{"Policy Length"}</MainBody>
              <Slider
                tipFormatter={yearFormatter}
                marks={yearMarks}
                min={10}
                defaultValue={year}
                max={30}
                onChange={value => {
                  setYear(value);
                  handleFormManualChange(
                    "policyLength",
                    value
                  );
                }}
              />
            </div>
          </QuestionHeader.Body>
        </Shadowcontainer>
      </div>
      <Shadowcontainer tw="-mt-2">
        <QuestionHeader>
          <div tw="w-full">
            <div tw="w-full flex items-center justify-between px-2">
              <Space>
                <SubBody tw="font-bold text-[#121F3E]">
                  Your details
                </SubBody>
                <span>
                  <Iconify
                    width={20}
                    height={20}
                    icon="bxs:edit"
                    onClick={reset}
                  />
                  <span tw="inline-block ml-1 text-base">
                    Edit
                  </span>
                </span>
              </Space>
              <SubBody tw="font-bold text-[#121F3E]">
                Estimated rate
              </SubBody>
            </div>
            <div tw="w-full flex items-center justify-between mt-4 px-2">
              <SubBody>
                {`${new Date(
                  form.dateOfBirth
                ).toDateString()} | ${form.gender} | ${
                  form.smoker ? "Smoker" : "Non Smoker"
                }`}
              </SubBody>
              <MainBody tw="font-bold text-[#121F3E]">
                {`$${form?.monthlyPay}/`}
                <span>month</span>
              </MainBody>
            </div>
          </div>
        </QuestionHeader>
      </Shadowcontainer>
      <div tw="w-7/12 lg:w-5/12 text-center mx-auto">
        <ButtonLarge
          block
          disabled={disabled}
          onClick={onClick}>
          {buttonText || "Next"}
        </ButtonLarge>
      </div>
    </StepWrapper>
  );
};

function QuotePrice({
  form,
  handleFormManualChange,
  handleMainStepNavigation,
}) {
  const { updateNavigationFunc, fixSubItem } =
    useButtonContext();
  const handleStepComplete = () => {
    handleMainStepNavigation(STEP_THREE);
    fixSubItem(1);
  };
  const handleMoveBack = () => {
    handleMainStepNavigation(STEP_ONE);
    fixSubItem(1);
  };
  React.useEffect(() => {
    const handleNavigation = () => {
      handleMainStepNavigation(STEP_ONE);
    };

    if (updateNavigationFunc) {
      updateNavigationFunc(handleNavigation, 3);
    }
  }, [handleMainStepNavigation, updateNavigationFunc]);
  return (
    <>
      <HeadingTwo tw="text-center">
        {"Lets Personalize your life insurance policy!"}
      </HeadingTwo>
      <SubStepItem
        buttonText={"Start Application"}
        disabled={!form.dateOfBirth}
        onClick={handleStepComplete}
        reset={handleMoveBack}
        form={form}
        handleFormManualChange={handleFormManualChange}
      />
    </>
  );
}

export default QuotePrice;
